.login-container, .dashboard-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}

input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

button {
  background: #007bff;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}
