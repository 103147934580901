.group-container {
    width: 95%;
    max-width: 900px;
    margin: 20px auto;
    padding: 15px;
    background: #1e1e1e;
    color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
    overflow-x: auto; /* Allow horizontal scrolling */
}

/* Title */
.group-title {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

/* Table Wrapper */
.group-table-wrapper {
    overflow-x: auto;
    width: 100%;
}

/* Table Container */
.group-table {
    display: grid;
    grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 1.5fr;
    text-align: center;
    min-width: 800px; /* Prevents it from squeezing on smaller screens */
}

/* Table Row */
.table-row {
    display: contents;
}

/* Each Cell */
.table-row > span {
    padding: 12px 8px;
    border-bottom: 1px solid #333;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Header */
.header {
    font-weight: bold;
    background: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Alternate Row Color */
.dark-row {
    background: rgba(255, 255, 255, 0.05);
}

/* Club Column */
.club {
    justify-content: flex-start;
    gap: 8px;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

/* Points */
.points {
    font-weight: bold;
    color: #00ff99;
}

/* Last 5 Matches */
.last-5 {
    display: flex;
    gap: 5px;
    justify-content: center;
}

/* Form Icons */
.form-icon {
    font-size: 14px;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.form-icon.W {
    background: #4caf50;
    color: white;
}

.form-icon.L {
    background: #f44336;
    color: white;
}

.form-icon.D {
    background: #ffcc00;
    color: white;
}

/* 🛠 Fix for Small Screens */
@media (max-width: 768px) {
    .group-container {
        width: 100%;
        padding: 10px;
    }

    .group-title {
        font-size: 18px;
    }

    /* Prevents table from squeezing too much */
    .group-table {
        min-width: 900px; /* Ensures full view on small screens */
    }

    /* Allows scrolling instead of compressing */
    .group-table-wrapper {
        overflow-x: auto;
        white-space: nowrap;
    }
}
