.team-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 5px;
  background: #1e1e1e;
  color: white;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.add-team {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  padding: 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background: #333;
  color: white;
}

.player-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.player-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remove-btn {
  width: 10%;
  background: red;
  border: none;
  color: white;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
}

.add-player-btn {
  padding: 10px;
  background: yellowgreen;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn {
  padding: 10px;
  background: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border-bottom: 1px solid #444;
  text-align: left;
}

th {
  background: #333;
}

.delete-btn {
  padding: 5px 10px;
  background: red;
  color: white;
  border: none;
  cursor: pointer;
}

 

