
.fixtures-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background: #171717;
    border-radius: 8px;
    text-align: center;
  }
  
  .fixtures-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .fixtures-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
  }
  
  .match-card {
    background: #201b1b;
    border-color: white;
    border: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .match-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  .match-time {
    font-size: 18px;
    color: green;
    font-weight: bold;
  }

  .match-date {
    font-size: 18px;
    color: grey;
    font-weight: bold;
  }

  .match-footer {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .edit-btn {
    background: #007bff;
    width: 50px;
    color: white;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    font-size: 14px;
  }
  
  .edit-btn:hover {
    background: #0056b3;
  }
  
  @media (max-width: 600px) {
    .match-info {
      flex-direction: column;
      text-align: center;
    }
    .score {
      margin: 10px 0;
    }
  }
  
  .score-text{
    color: yellow
  }
