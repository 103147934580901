.dashboard-container {
    width: 90%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #1e1e1e;
    color: white;
    border-radius: 8px;
    text-align: center;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .dashboard-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .dashboard-card {
    width: 50%;
    background: #007bff;
    color: white;
    padding: 15px;
    text-decoration: none;
    border-radius: 6px;
    transition: background 0.3s, transform 0.2s;
    text-align: center;
  }
  
  .dashboard-card:hover {
    background: #0056b3;
    transform: scale(1.05);
  }
  
  .dashboard-card h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .dashboard-card p {
    font-size: 14px;
    opacity: 0.9;
  }
  
  .logout-btn {
    background: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s;
    width: 80%;
  }
  
  .logout-btn:hover {
    background: #c82333;
  }
  