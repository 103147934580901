/* General Page Styling */
body {
  background-color: #121212; /* Dark background */
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header Styling */
.header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  background: #1e1e1e; /* Dark grey header */
  border-bottom: 2px solid #ffcc00; /* Yellow highlight */
}

/* Group Table Container */
.group-container {
  width: 90%;
  max-width: 800px; /* Prevents it from being too wide */
  margin: 20px auto;
  padding: 20px;
  background: #1a1a1a; /* Darker box for tables */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
}

/* Center the Group Name */
.group-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Table Styling */
.table-container {
  overflow-x: auto; /* Enables scrolling on small screens */
}

.table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* Ensures table doesn't shrink too much */
}

.table th, .table td {
  padding: 10px;
  text-align: center;
}

.table th {
  background-color: #333;
}

.table tr:nth-child(even) {
  background-color: #222;
}

/* 📌 Fix for Small Screens */
@media (max-width: 768px) {
  .group-container {
      padding: 15px;
      width: 95%;
  }

  .header {
      font-size: 20px;
      padding: 15px;
  }

  .group-title {
      font-size: 18px;
  }

  .table-container {
      overflow-x: auto;
  }

  .table th, .table td {
      padding: 8px;
      font-size: 14px;
  }
}
