@media (max-width: 768px) {
  .table-container {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
  }

  th, td {
    white-space: normal;
    word-wrap: break-word;
    padding: 8px;
  }
}
