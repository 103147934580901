.login-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background: #1e1e1e;
    color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    text-align: center;
}

h2 {
    margin-bottom: 20px;
}

.error-msg {
    color: #ff4d4d;
    font-size: 14px;
    margin-bottom: 10px;
}

.input-group {
    margin-bottom: 15px;
    align-items: center;
    text-align: left;
}

.input-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background: #2a2a2a;
    color: white;
    font-size: 14px;
}

button {
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background: #0056b3;
}
